import Hero from "../../components/hero"
import HeroSlider from "../../components/heroSlider"
import InfoRows from "../../components/infoRows"
import ItemsRows from "../../components/itemsRows"
import Courses from "../../components/courses"

import Quote from "../../components/quote"
import localize from "../../components/localize"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import "twin.macro"
import React from "react"
import SEO from "../../components/seo"
import { useIntl } from "gatsby-plugin-intl"

export const query = graphql`
  {
    route: sanityPage(title: { eq: "Siele" }) {
      ...PlugComponents
    }
  }
`

const SielePage = props => {
  const intl = useIntl()

  const { data, errors } = props

  if (errors) {
    return <Layout>error</Layout>
  }

  const page = data.page || data.route
  //console.log(page.content)

  const content = (page.content || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} cta="courses" />
          break
        case "heroSlider":
          el = <HeroSlider key={c._key} {...c} />
          break
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />
          break
        case "itemsRows":
          el = <ItemsRows key={c._key} {...c} />
          break
        case "quote":
          el = <Quote key={c._key} {...c} />
          break
        case "coursesList":
          el = <Courses key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <Layout>
      <SEO lang={intl.locale} title="Siele" />
      <div className="">{content}</div>
    </Layout>
  )
}
export default localize(SielePage)
